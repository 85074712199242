@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: Inter, system-ui, sans-serif;
  }

  /* clear default iOS styling from inputs */
  input[type="text"] {
    @apply appearance-none;
  }

  a {
    @apply text-inherit no-underline;
  }

  button:focus {
    @apply outline-none;
  }

  [role="menuitem"] {
    @apply mb-1 flex-1 overflow-hidden rounded-sm;
  }

  [role="menuitem"] > div:nth-child(1) {
    @apply flex flex-1 flex-row items-center p-2;
  }

  [role="menuitem"]:hover,
  [role="menuitem"]:focus-within {
    @apply cursor-pointer rounded-lg bg-gray-900 bg-opacity-5 outline-none;
  }

  [data-color-scheme="dark"] [role="menuitem"]:hover,
  [data-color-scheme="dark"] [role="menuitem"]:focus-within {
    @apply bg-gray-100 bg-opacity-5;
  }

  [role="menuitem"].danger:hover,
  [role="menuitem"].danger:focus-within {
    @apply bg-red-500 bg-opacity-20;
  }

  [data-color-scheme="dark"] [role="menuitem"].danger:hover,
  [data-color-scheme="dark"] [role="menuitem"].danger:focus-within {
    @apply bg-red-500 bg-opacity-20;
  }

  [role="menuitem"] > div > div:nth-child(3) {
    @apply ml-2 flex-1 font-semibold text-gray-700;
  }

  [data-color-scheme="dark"] [role="menuitem"] > div > div:nth-child(3) {
    @apply text-neutral-300;
  }

  [role="menuitem"].danger:hover > div > div:nth-child(3),
  [role="menuitem"].danger:hover svg > *,
  [role="menuitem"].danger:focus-within > div > div:nth-child(3),
  [role="menuitem"].danger:focus-within svg > * {
    @apply text-red-500;
  }
}

[data-color-scheme="dark"] {
  color-scheme: dark;
  background-color: #000;
}

[data-color-scheme="light"] {
  color-scheme: light;
  background-color: #fff;
}

div.reactEasyCrop_CropAreaGrid::before,
div.reactEasyCrop_CropAreaGrid::after {
  all: unset;
}

/**
 * Building on the RNWeb reset:
 * https://github.com/necolas/react-native-web/blob/master/packages/react-native-web/src/exports/StyleSheet/initialRules.js
 */
html,
body,
#__next {
  width: 100%;
  /* To smooth any scrolling behavior */
  -webkit-overflow-scrolling: touch;
  margin: 0px;
  padding: 0px;
  /* Allows content to fill the viewport and go beyond the bottom */
  min-height: 100%;
}

#__next {
  flex-shrink: 0;
  flex-basis: auto;
  flex-direction: column;
  flex-grow: 1;
  display: flex;
  flex: 1;
}

html {
  /* Prevent text size change on orientation change https://gist.github.com/tfausak/2222823#file-ios-8-web-app-html-L138 */
  -webkit-text-size-adjust: 100%;
  height: 100%;
  overflow-y: scroll;
  overscroll-behavior-y: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: scrollbar;
}

body {
  display: flex;
  overflow-y: visible;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.pretty-scrollbar::-webkit-scrollbar-thumb {
  height: 56px;
  border-radius: 8px;
  border: 4px solid transparent;
  background-clip: content-box;
  /* background-color: transparent; */
  transition: background-color 2s;
  transition-duration: 200ms;
}
.pretty-scrollbar:hover::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
}
[data-color-scheme="dark"] .pretty-scrollbar:hover::-webkit-scrollbar-thumb {
  background-color: #6b6b6b;
}
.pretty-scrollbar::-webkit-scrollbar {
  width: 16px;
  background: transparent;
}
.react-datepicker__input-container {
  display: flex;
}
.react-datepicker__input-container input {
  flex: 1;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0);
}

input:focus-visible {
  outline: none;
  box-shadow: none !important;
}
textarea:focus-visible {
  outline: none;
  box-shadow: none !important;
}

.no-scroll {
  overflow: hidden;
  height: 100%;
}

.numeric {
  font-variant-numeric: tabular-nums !important;
}

@media (prefers-color-scheme: dark) {
  :root {
    --primary-color: #fd8700; /*I had to change this due to not very good contrast between blue and black*/
    --body-bg: black;
    --text-color: white;
    --formatted-content: #333;
  }
}

@media (prefers-color-scheme: dark) {
  iframe[src*="disqus.com"] {
    color-scheme: dark;
  }
}
